import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"
import Header from "../components/page/Header"
import { ThemeProvider } from "@material-ui/styles"
import { theme } from "../var/theme"
import NoSsr from "@material-ui/core/NoSsr"
import { Card, CardContent, createStyles, makeStyles, useScrollTrigger } from "@material-ui/core"
import Zoom from "@material-ui/core/Zoom"
import Fab from "@material-ui/core/Fab"
import { KeyboardArrowUpRounded as KeyboardArrowUpIcon } from "@material-ui/icons"
import { scrollTo } from "../var/util"
import Footer from "../components/partials/Footer"
import Typography from "@material-ui/core/Typography"
import Meta from "../components/Meta"
import Link from "@material-ui/core/Link"
import PageTitle from "../components/buttons/PageTitle"

const useStyles = makeStyles((t) => createStyles({
  main: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  scroll: {
    position: "fixed",
    bottom: t.spacing(2),
    right: t.spacing(2),
  },
  toolbar: {
    ...t.mixins.toolbar,
    background: theme.custom.fancyBackground,
  },
  content: {
    flex: 1,
  },
  card: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}))

function ScrollTop(props) {
  const { children, window } = props
  const classes = useStyles()
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = event => {
    scrollTo("#top-anchor")
  }

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.scroll}>
        {children}
      </div>
    </Zoom>
  )
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}
// https://app-privacy-policy-generator.nisrulz.com/
const TermsPage = () => {
  const classes = useStyles()
  // const prefersDarkMode = 0//useMediaQuery("(prefers-color-scheme: dark)")
  // const appTheme = React.useMemo(
  //   () =>
  //     prefersDarkMode ? darkTheme : theme,
  //   [prefersDarkMode],
  // )
  return (
    <React.Fragment>
      <Meta title={"Terms and Conditions"} />
      <NoSsr>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <main className={classes.main}>
            <Header title="Memo Reminders"/>
            <section id={"content"} className={classes.content}>
              <div className={classes.toolbar}/>
              <Container maxWidth={"md"}>
                <PageTitle title={"Terms and Conditions"}/>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      By downloading or using the app, these terms will
                      automatically apply to you – you should make sure therefore
                      that you read them carefully before using the app. You’re not
                      allowed to copy, or modify the app, any part of the app, or
                      our trademarks in any way. You’re not allowed to attempt to
                      extract the source code of the app, and you also shouldn’t try
                      to translate the app into other languages, or make derivative
                      versions. The app itself, and all the trade marks, copyright,
                      database rights and other intellectual property rights related
                      to it, still belong to <b>datanasov.com</b>.
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      <b>datanasov.com</b> is committed to ensuring that the app is
                      as useful and efficient as possible. For that reason, we
                      reserve the right to make changes to the app or to charge for
                      its services, at any time and for any reason. We will never
                      charge you for the app or its services without making it very
                      clear to you exactly what you’re paying for.
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      The Memo Reminders app stores and processes personal data that
                      you have provided to us, in order to provide our
                      Service. It’s your responsibility to keep your phone and
                      access to the app secure. We therefore recommend that you do
                      not jailbreak or root your phone, which is the process of
                      removing software restrictions and limitations imposed by the
                      official operating system of your device. It could make your
                      phone vulnerable to malware/viruses/malicious programs,
                      compromise your phone’s security features and it could mean
                      that the Memo Reminders app won’t work properly or at all.
                    </Typography>
                    <div>
                      <Typography align={"left"} variant={"body1"} gutterBottom>
                        The app does use third party services that declare their own
                        Terms and Conditions.
                      </Typography>

                      <Typography align={"left"} variant={"body1"} gutterBottom>
                        Link to Terms and Conditions of third party service
                        providers used by the app
                      </Typography>
                      <ul>
                        <li><Link rel="noopener" href="https://policies.google.com/terms" target="_blank">Google Play
                          Services</Link></li>
                        <li><Link rel="noopener" href="https://firebase.google.com/terms/analytics" target="_blank">Google
                          Analytics for
                          Firebase</Link></li>
                      </ul>
                    </div>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      You should be aware that there are certain things
                      that <b>datanasov.com</b> will not take responsibility for. Certain
                      functions of the app will require the app to have an active
                      internet connection. The connection can be Wi-Fi, or provided
                      by your mobile network provider, but datanasov.com
                      cannot take responsibility for the app not working at full
                      functionality if you don’t have access to Wi-Fi, and you don’t
                      have any of your data allowance left.
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom></Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      If you’re using the app outside of an area with Wi-Fi, you
                      should remember that your terms of the agreement with your
                      mobile network provider will still apply. As a result, you may
                      be charged by your mobile provider for the cost of data for
                      the duration of the connection while accessing the app, or
                      other third party charges. In using the app, you’re accepting
                      responsibility for any such charges, including roaming data
                      charges if you use the app outside of your home territory
                      (i.e. region or country) without turning off data roaming. If
                      you are not the bill payer for the device on which you’re
                      using the app, please be aware that we assume that you have
                      received permission from the bill payer for using the app.
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      Along the same lines, <b>datanasov.com</b> cannot always take
                      responsibility for the way you use the app i.e. You need to
                      make sure that your device stays charged – if it runs out of
                      battery and you can’t turn it on to avail the Service, <b>datanasov.com</b> cannot accept
                      responsibility.
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      With respect to datanasov.com’s responsibility for your
                      use of the app, when you’re using the app, it’s important to
                      bear in mind that although we endeavour to ensure that it is
                      updated and correct at all times, we do rely on third parties
                      to provide information to us so that we can make it available
                      to you. <b>datanasov.com</b> accepts no liability for any
                      loss, direct or indirect, you experience as a result of
                      relying wholly on this functionality of the app.
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      At some point, we may wish to update the app. The app is
                      currently available on Android – the requirements for
                      system(and for any additional systems we
                      decide to extend the availability of the app to) may change,
                      and you’ll need to download the updates if you want to keep
                      using the app. <b>datanasov.com</b> does not promise that it
                      will always update the app so that it is relevant to you
                      and/or works with the Android version that you have
                      installed on your device. However, you promise to always
                      accept updates to the application when offered to you, We may
                      also wish to stop providing the app, and may terminate use of
                      it at any time without giving notice of termination to you.
                      Unless we tell you otherwise, upon any termination, (a) the
                      rights and licenses granted to you in these terms will end;
                      (b) you must stop using the app, and (if needed) delete it
                      from your device.
                    </Typography>

                    <Typography align={"center"} variant={"h6"}>Changes to This Terms and
                      Conditions</Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      We may update our Terms and Conditions
                      from time to time. Thus, you are advised to review this page
                      periodically for any changes. we will
                      notify you of any changes by posting the new Terms and
                      Conditions on this page.
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      These terms and conditions are effective as of 2020-06-01.
                    </Typography>

                    <Typography align={"center"} variant={"h6"}>Contact Us</Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      If you have any questions or suggestions about our
                      Terms and Conditions, do not hesitate to contact us
                      at <Link
                      href={"mailto:hello@memoreminders.com"}>hello@memoreminders.com</Link>.
                    </Typography>

                  </CardContent>
                </Card>
              </Container>
            </section>
            <Footer excludeSupport={true}/>
          </main>
          <ScrollTop>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon/>
            </Fab>
          </ScrollTop>
        </ThemeProvider>
      </NoSsr>
    </React.Fragment>
  )
}

export default TermsPage
